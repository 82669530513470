
<!-- Correct Button and Wrong Button Local Storage -->
var l = localStorage;
  
function ccc() {var c = $('#sbox');
c.html(Number(c.html())+4); l.setItem('qs1', c.html());$(".next-right").addClass("mynext");}

function www() {var w = $('#sbox');
w.html(Number(w.html())-1); l.setItem('qs1', w.html());}

  <!-- CBS = Correct Button Storage -->
function scb1(){l.setItem('1s1','0');ccc();}function scb2(){l.setItem('2s1','0');ccc();}
function scb3(){l.setItem('3s1','0');ccc();}function scb4(){l.setItem('4s1','0');ccc();}
function scb5(){l.setItem('5s1','0');ccc();}function scb6(){l.setItem('6s1','0');ccc();}
function scb7(){l.setItem('7s1','0');ccc();}function scb8(){l.setItem('8s1','0');ccc();}
function scb9(){l.setItem('9s1','0');ccc();}function scb10(){l.setItem('10s1','0');ccc();}
function scb11(){l.setItem('11s1','0');ccc();}function scb12(){l.setItem('12s1','0');ccc();}
function scb13(){l.setItem('13s1','0');ccc();}function scb14(){l.setItem('14s1','0');ccc();}
function scb15(){l.setItem('15s1','0');ccc();}function scb16(){l.setItem('16s1','0');ccc();}
function scb17(){l.setItem('17s1','0');ccc();}function scb18(){l.setItem('18s1','0');ccc();}
function scb19(){l.setItem('19s1','0');ccc();}function scb20(){l.setItem('20s1','0');ccc();}
function scb21(){l.setItem('21s1','0');ccc();}function scb22(){l.setItem('22s1','0');ccc();}
function scb23(){l.setItem('23s1','0');ccc();}function scb24(){l.setItem('24s1','0');ccc();}
function scb25(){l.setItem('25s1','0');ccc();}function scb26(){l.setItem('26s1','0');ccc();}
function scb27(){l.setItem('27s1','0');ccc();}function scb28(){l.setItem('28s1','0');ccc();}
function scb29(){l.setItem('29s1','0');ccc();}function scb30(){l.setItem('30s1','0');ccc();}
function scb31(){l.setItem('31s1','0');ccc();}function scb32(){l.setItem('32s1','0');ccc();}
function scb33(){l.setItem('33s1','0');ccc();}function scb34(){l.setItem('34s1','0');ccc();}
function scb35(){l.setItem('35s1','0');ccc();}function scb36(){l.setItem('36s1','0');ccc();}
function scb37(){l.setItem('37s1','0');ccc();}function scb38(){l.setItem('38s1','0');ccc();}
function scb39(){l.setItem('39s1','0');ccc();}function scb40(){l.setItem('40s1','0');ccc();}
function scb41(){l.setItem('41s1','0');ccc();}function scb42(){l.setItem('42s1','0');ccc();}
function scb43(){l.setItem('43s1','0');ccc();}function scb44(){l.setItem('44s1','0');ccc();}
function scb45(){l.setItem('45s1','0');ccc();}function scb46(){l.setItem('46s1','0');ccc();}
function scb47(){l.setItem('47s1','0');ccc();}function scb48(){l.setItem('48s1','0');ccc();}
function scb49(){l.setItem('49s1','0');ccc();}function scb50(){l.setItem('50s1','0');ccc();}
function scb51(){l.setItem('51s1','0');ccc();}function scb52(){l.setItem('52s1','0');ccc();}
function scb53(){l.setItem('53s1','0');ccc();}function scb54(){l.setItem('54s1','0');ccc();}
function scb55(){l.setItem('55s1','0');ccc();}function scb56(){l.setItem('56s1','0');ccc();}
function scb57(){l.setItem('57s1','0');ccc();}function scb58(){l.setItem('58s1','0');ccc();}
function scb59(){l.setItem('59s1','0');ccc();}function scb60(){l.setItem('60s1','0');ccc();}


<!-- WBS = Wrong Button Storage -->
function swb1(){l.setItem('1s1','1');www();}
function swb2(){l.setItem('1s1','2');www();}
function swb3(){l.setItem('1s1','3');www();}

function swb4(){l.setItem('2s1','1');www();}
function swb5(){l.setItem('2s1','2');www();}
function swb6(){l.setItem('2s1','3');www();}

function swb7(){l.setItem('3s1','1');www();}
function swb8(){l.setItem('3s1','2');www();}
function swb9(){l.setItem('3s1','3');www();}

function swb10(){l.setItem('4s1','1');www();}
function swb11(){l.setItem('4s1','2');www();}
function swb12(){l.setItem('4s1','3');www();}

function swb13(){l.setItem('5s1','1');www();}
function swb14(){l.setItem('5s1','2');www();}
function swb15(){l.setItem('5s1','3');www();}

function swb16(){l.setItem('6s1','1');www();}
function swb17(){l.setItem('6s1','2');www();}
function swb18(){l.setItem('6s1','3');www();}

function swb19(){l.setItem('7s1','1');www();}
function swb20(){l.setItem('7s1','2');www();}
function swb21(){l.setItem('7s1','3');www();}

function swb22(){l.setItem('8s1','1');www();}
function swb23(){l.setItem('8s1','2');www();}
function swb24(){l.setItem('8s1','3');www();}

function swb25(){l.setItem('9s1','1');www();}
function swb26(){l.setItem('9s1','2');www();}
function swb27(){l.setItem('9s1','3');www();}

function swb28(){l.setItem('10s1','1');www();}
function swb29(){l.setItem('10s1','2');www();}
function swb30(){l.setItem('10s1','3');www();}

function swb31(){l.setItem('11s1','1');www();}
function swb32(){l.setItem('11s1','2');www();}
function swb33(){l.setItem('11s1','3');www();}

function swb34(){l.setItem('12s1','1');www();}
function swb35(){l.setItem('12s1','2');www();}
function swb36(){l.setItem('12s1','3');www();}

function swb37(){l.setItem('13s1','1');www();}
function swb38(){l.setItem('13s1','2');www();}
function swb39(){l.setItem('13s1','3');www();}

function swb40(){l.setItem('14s1','1');www();}
function swb41(){l.setItem('14s1','2');www();}
function swb42(){l.setItem('14s1','3');www();}

function swb43(){l.setItem('15s1','1');www();}
function swb44(){l.setItem('15s1','2');www();}
function swb45(){l.setItem('15s1','3');www();}

function swb46(){l.setItem('16s1','1');www();}
function swb47(){l.setItem('16s1','2');www();}
function swb48(){l.setItem('16s1','3');www();}

function swb49(){l.setItem('17s1','1');www();}
function swb50(){l.setItem('17s1','2');www();}
function swb51(){l.setItem('17s1','3');www();}

function swb52(){l.setItem('18s1','1');www();}
function swb53(){l.setItem('18s1','2');www();}
function swb54(){l.setItem('18s1','3');www();}

function swb55(){l.setItem('19s1','1');www();}
function swb56(){l.setItem('19s1','2');www();}
function swb57(){l.setItem('19s1','3');www();}

function swb58(){l.setItem('20s1','1');www();}
function swb59(){l.setItem('20s1','2');www();}
function swb60(){l.setItem('20s1','3');www();}

function swb61(){l.setItem('21s1','1');www();}
function swb62(){l.setItem('21s1','2');www();}
function swb63(){l.setItem('21s1','3');www();}

function swb64(){l.setItem('22s1','1');www();}
function swb65(){l.setItem('22s1','2');www();}
function swb66(){l.setItem('22s1','3');www();}

function swb67(){l.setItem('23s1','1');www();}
function swb68(){l.setItem('23s1','2');www();}
function swb69(){l.setItem('23s1','3');www();}

function swb70(){l.setItem('24s1','1');www();}
function swb71(){l.setItem('24s1','2');www();}
function swb72(){l.setItem('24s1','3');www();}

function swb73(){l.setItem('25s1','1');www();}
function swb74(){l.setItem('25s1','2');www();}
function swb75(){l.setItem('25s1','3');www();}

function swb76(){l.setItem('26s1','1');www();}
function swb77(){l.setItem('26s1','2');www();}
function swb78(){l.setItem('26s1','3');www();}

function swb79(){l.setItem('27s1','1');www();}
function swb80(){l.setItem('27s1','2');www();}
function swb81(){l.setItem('27s1','3');www();}

function swb82(){l.setItem('28s1','1');www();}
function swb83(){l.setItem('28s1','2');www();}
function swb84(){l.setItem('28s1','3');www();}

function swb85(){l.setItem('29s1','1');www();}
function swb86(){l.setItem('29s1','2');www();}
function swb87(){l.setItem('29s1','3');www();}

function swb88(){l.setItem('30s1','1');www();}
function swb89(){l.setItem('30s1','2');www();}
function swb90(){l.setItem('30s1','3');www();}

function swb91(){l.setItem('31s1','1');www();}
function swb92(){l.setItem('31s1','2');www();}
function swb93(){l.setItem('31s1','3');www();}

function swb94(){l.setItem('32s1','1');www();}
function swb95(){l.setItem('32s1','2');www();}
function swb96(){l.setItem('32s1','3');www();}

function swb97(){l.setItem('33s1','1');www();}
function swb98(){l.setItem('33s1','2');www();}
function swb99(){l.setItem('33s1','3');www();}

function swb100(){l.setItem('34s1','1');www();}
function swb101(){l.setItem('34s1','2');www();}
function swb102(){l.setItem('34s1','3');www();}

function swb103(){l.setItem('35s1','1');www();}
function swb104(){l.setItem('35s1','2');www();}
function swb105(){l.setItem('35s1','3');www();}

function swb106(){l.setItem('36s1','1');www();}
function swb107(){l.setItem('36s1','2');www();}
function swb108(){l.setItem('36s1','3');www();}

function swb109(){l.setItem('37s1','1');www();}
function swb110(){l.setItem('37s1','2');www();}
function swb111(){l.setItem('37s1','3');www();}

function swb112(){l.setItem('38s1','1');www();}
function swb113(){l.setItem('38s1','2');www();}
function swb114(){l.setItem('38s1','3');www();}

function swb115(){l.setItem('39s1','1');www();}
function swb116(){l.setItem('39s1','2');www();}
function swb117(){l.setItem('39s1','3');www();}

function swb118(){l.setItem('40s1','1');www();}
function swb119(){l.setItem('40s1','2');www();}
function swb120(){l.setItem('40s1','3');www();}

function swb121(){l.setItem('41s1','1');www();}
function swb122(){l.setItem('41s1','2');www();}
function swb123(){l.setItem('41s1','3');www();}

function swb124(){l.setItem('42s1','1');www();}
function swb125(){l.setItem('42s1','2');www();}
function swb126(){l.setItem('42s1','3');www();}

function swb127(){l.setItem('43s1','1');www();}
function swb128(){l.setItem('43s1','2');www();}
function swb129(){l.setItem('43s1','3');www();}

function swb130(){l.setItem('44s1','1');www();}
function swb131(){l.setItem('44s1','2');www();}
function swb132(){l.setItem('44s1','3');www();}

function swb133(){l.setItem('45s1','1');www();}
function swb134(){l.setItem('45s1','2');www();}
function swb135(){l.setItem('45s1','3');www();}

function swb136(){l.setItem('46s1','1');www();}
function swb137(){l.setItem('46s1','2');www();}
function swb138(){l.setItem('46s1','3');www();}

function swb139(){l.setItem('47s1','1');www();}
function swb140(){l.setItem('47s1','2');www();}
function swb141(){l.setItem('47s1','3');www();}

function swb142(){l.setItem('48s1','1');www();}
function swb143(){l.setItem('48s1','2');www();}
function swb144(){l.setItem('48s1','3');www();}

function swb145(){l.setItem('49s1','1');www();}
function swb146(){l.setItem('49s1','2');www();}
function swb147(){l.setItem('49s1','3');www();}

function swb148(){l.setItem('50s1','1');www();}
function swb149(){l.setItem('50s1','2');www();}
function swb150(){l.setItem('50s1','3');www();}

function swb151(){l.setItem('51s1','1');www();}
function swb152(){l.setItem('51s1','2');www();}
function swb153(){l.setItem('51s1','3');www();}

function swb154(){l.setItem('52s1','1');www();}
function swb155(){l.setItem('52s1','2');www();}
function swb156(){l.setItem('52s1','3');www();}

function swb157(){l.setItem('53s1','1');www();}
function swb158(){l.setItem('53s1','2');www();}
function swb159(){l.setItem('53s1','3');www();}

function swb160(){l.setItem('54s1','1');www();}
function swb161(){l.setItem('54s1','2');www();}
function swb162(){l.setItem('54s1','3');www();}

function swb163(){l.setItem('55s1','1');www();}
function swb164(){l.setItem('55s1','2');www();}
function swb165(){l.setItem('55s1','3');www();}

function swb166(){l.setItem('56s1','1');www();}
function swb167(){l.setItem('56s1','2');www();}
function swb168(){l.setItem('56s1','3');www();}

function swb169(){l.setItem('57s1','1');www();}
function swb170(){l.setItem('57s1','2');www();}
function swb171(){l.setItem('57s1','3');www();}

function swb172(){l.setItem('58s1','1');www();}
function swb173(){l.setItem('58s1','2');www();}
function swb174(){l.setItem('58s1','3');www();}

function swb175(){l.setItem('59s1','1');www();}
function swb176(){l.setItem('59s1','2');www();}
function swb177(){l.setItem('59s1','3');www();}

function swb178(){l.setItem('60s1','1');www();}
function swb179(){l.setItem('60s1','2');www();}
function swb180(){l.setItem('60s1','3');www();}

function cont(){

var qs1 = l.getItem('qs1'),
_1s1 = l.getItem('1s1'),
_2s1 = l.getItem('2s1'),
_3s1 = l.getItem('3s1'),
_4s1 = l.getItem('4s1'),
_5s1 = l.getItem('5s1'),
_6s1 = l.getItem('6s1'),
_7s1 = l.getItem('7s1'),
_8s1 = l.getItem('8s1'),
_9s1 = l.getItem('9s1'),
_10s1 = l.getItem('10s1'),
_11s1 = l.getItem('11s1'),
_12s1 = l.getItem('12s1'),
_13s1 = l.getItem('13s1'),
_14s1 = l.getItem('14s1'),
_15s1 = l.getItem('15s1'),
_16s1 = l.getItem('16s1'),
_17s1 = l.getItem('17s1'),
_18s1 = l.getItem('18s1'),
_19s1 = l.getItem('19s1'),
_20s1 = l.getItem('20s1'),
_21s1 = l.getItem('21s1'),
_22s1 = l.getItem('22s1'),
_23s1 = l.getItem('23s1'),
_24s1 = l.getItem('24s1'),
_25s1 = l.getItem('25s1'),
_26s1 = l.getItem('26s1'),
_27s1 = l.getItem('27s1'),
_28s1 = l.getItem('28s1'),
_29s1 = l.getItem('29s1'),
_30s1 = l.getItem('30s1'),
_31s1 = l.getItem('31s1'),
_32s1 = l.getItem('32s1'),
_33s1 = l.getItem('33s1'),
_34s1 = l.getItem('34s1'),
_35s1 = l.getItem('35s1'),
_36s1 = l.getItem('36s1'),
_37s1 = l.getItem('37s1'),
_38s1 = l.getItem('38s1'),
_39s1 = l.getItem('39s1'),
_40s1 = l.getItem('40s1'),
_41s1 = l.getItem('41s1'),
_42s1 = l.getItem('42s1'),
_43s1 = l.getItem('43s1'),
_44s1 = l.getItem('44s1'),
_45s1 = l.getItem('45s1'),
_46s1 = l.getItem('46s1'),
_47s1 = l.getItem('47s1'),
_48s1 = l.getItem('48s1'),
_49s1 = l.getItem('49s1'),
_50s1 = l.getItem('50s1'),
_51s1 = l.getItem('51s1'),
_52s1 = l.getItem('52s1'),
_53s1 = l.getItem('53s1'),
_54s1 = l.getItem('54s1'),
_55s1 = l.getItem('55s1'),
_56s1 = l.getItem('56s1'),
_57s1 = l.getItem('57s1'),
_58s1 = l.getItem('58s1'),
_59s1 = l.getItem('59s1'),
_60s1 = l.getItem('60s1');
    

if(qs1){$('#sbox').html(qs1);}
  
if(_1s1 === '0'){$('.q1 ww').hide();$('.q1 ch').show();}
if(_1s1 === '1'){$('.q1 .b1').addClass('wbtr');}
if(_1s1 === '2'){$('.q1 .b2').addClass('wbtr');}
if(_1s1 === '3'){$('.q1 .b3').addClass('wbtr');}

if(_2s1 === '0'){$('.q2 ww').hide();$('.q2 ch').show();}
if(_2s1 === '1'){$('.q2 .b1').addClass('wbtr');}
if(_2s1 === '2'){$('.q2 .b2').addClass('wbtr');}
if(_2s1 === '3'){$('.q2 .b3').addClass('wbtr');}

if(_3s1 === '0'){$('.q3 ww').hide();$('.q3 ch').show();}
if(_3s1 === '1'){$('.q3 .b1').addClass('wbtr');}
if(_3s1 === '2'){$('.q3 .b2').addClass('wbtr');}
if(_3s1 === '3'){$('.q3 .b3').addClass('wbtr');}

if(_4s1 === '0'){$('.q4 ww').hide();$('.q4 ch').show();}
if(_4s1 === '1'){$('.q4 .b1').addClass('wbtr');}
if(_4s1 === '2'){$('.q4 .b2').addClass('wbtr');}
if(_4s1 === '3'){$('.q4 .b3').addClass('wbtr');}

if(_5s1 === '0'){$('.q5 ww').hide();$('.q5 ch').show();}
if(_5s1 === '1'){$('.q5 .b1').addClass('wbtr');}
if(_5s1 === '2'){$('.q5 .b2').addClass('wbtr');}
if(_5s1 === '3'){$('.q5 .b3').addClass('wbtr');}

if(_6s1 === '0'){$('.q6 ww').hide();$('.q6 ch').show();}
if(_6s1 === '1'){$('.q6 .b1').addClass('wbtr');}
if(_6s1 === '2'){$('.q6 .b2').addClass('wbtr');}
if(_6s1 === '3'){$('.q6 .b3').addClass('wbtr');}

if(_7s1 === '0'){$('.q7 ww').hide();$('.q7 ch').show();}
if(_7s1 === '1'){$('.q7 .b1').addClass('wbtr');}
if(_7s1 === '2'){$('.q7 .b2').addClass('wbtr');}
if(_7s1 === '3'){$('.q7 .b3').addClass('wbtr');}

if(_8s1 === '0'){$('.q8 ww').hide();$('.q8 ch').show();}
if(_8s1 === '1'){$('.q8 .b1').addClass('wbtr');}
if(_8s1 === '2'){$('.q8 .b2').addClass('wbtr');}
if(_8s1 === '3'){$('.q8 .b3').addClass('wbtr');}

if(_9s1 === '0'){$('.q9 ww').hide();$('.q9 ch').show();}
if(_9s1 === '1'){$('.q9 .b1').addClass('wbtr');}
if(_9s1 === '2'){$('.q9 .b2').addClass('wbtr');}
if(_9s1 === '3'){$('.q9 .b3').addClass('wbtr');}

if(_10s1 === '0'){$('.q10 ww').hide();$('.q10 ch').show();}
if(_10s1 === '1'){$('.q10 .b1').addClass('wbtr');}
if(_10s1 === '2'){$('.q10 .b2').addClass('wbtr');}
if(_10s1 === '3'){$('.q10 .b3').addClass('wbtr');}

if(_11s1 === '0'){$('.q11 ww').hide();$('.q11 ch').show();}
if(_11s1 === '1'){$('.q11 .b1').addClass('wbtr');}
if(_11s1 === '2'){$('.q11 .b2').addClass('wbtr');}
if(_11s1 === '3'){$('.q11 .b3').addClass('wbtr');}
 
if(_12s1 === '0'){$('.q12 ww').hide();$('.q12 ch').show();}
if(_12s1 === '1'){$('.q12 .b1').addClass('wbtr');}
if(_12s1 === '2'){$('.q12 .b2').addClass('wbtr');}
if(_12s1 === '3'){$('.q12 .b3').addClass('wbtr');}

if(_13s1 === '0'){$('.q13 ww').hide();$('.q13 ch').show();}
if(_13s1 === '1'){$('.q13 .b1').addClass('wbtr');}
if(_13s1 === '2'){$('.q13 .b2').addClass('wbtr');}
if(_13s1 === '3'){$('.q13 .b3').addClass('wbtr');}

if(_14s1 === '0'){$('.q14 ww').hide();$('.q14 ch').show();}
if(_14s1 === '1'){$('.q14 .b1').addClass('wbtr');}
if(_14s1 === '2'){$('.q14 .b2').addClass('wbtr');}
if(_14s1 === '3'){$('.q14 .b3').addClass('wbtr');}

if(_15s1 === '0'){$('.q15 ww').hide();$('.q15 ch').show();}
if(_15s1 === '1'){$('.q15 .b1').addClass('wbtr');}
if(_15s1 === '2'){$('.q15 .b2').addClass('wbtr');}
if(_15s1 === '3'){$('.q15 .b3').addClass('wbtr');}

if(_16s1 === '0'){$('.q16 ww').hide();$('.q16 ch').show();}
if(_16s1 === '1'){$('.q16 .b1').addClass('wbtr');}
if(_16s1 === '2'){$('.q16 .b2').addClass('wbtr');}
if(_16s1 === '3'){$('.q16 .b3').addClass('wbtr');}

if(_17s1 === '0'){$('.q17 ww').hide();$('.q17 ch').show();}
if(_17s1 === '1'){$('.q17 .b1').addClass('wbtr');}
if(_17s1 === '2'){$('.q17 .b2').addClass('wbtr');}
if(_17s1 === '3'){$('.q17 .b3').addClass('wbtr');}

if(_18s1 === '0'){$('.q18 ww').hide();$('.q18 ch').show();}
if(_18s1 === '1'){$('.q18 .b1').addClass('wbtr');}
if(_18s1 === '2'){$('.q18 .b2').addClass('wbtr');}
if(_18s1 === '3'){$('.q18 .b3').addClass('wbtr');}

if(_19s1 === '0'){$('.q19 ww').hide();$('.q19 ch').show();}
if(_19s1 === '1'){$('.q19 .b1').addClass('wbtr');}
if(_19s1 === '2'){$('.q19 .b2').addClass('wbtr');}
if(_19s1 === '3'){$('.q19 .b3').addClass('wbtr');}

if(_20s1 === '0'){$('.q20 ww').hide();$('.q20 ch').show();}
if(_20s1 === '1'){$('.q20 .b1').addClass('wbtr');}
if(_20s1 === '2'){$('.q20 .b2').addClass('wbtr');}
if(_20s1 === '3'){$('.q20 .b3').addClass('wbtr');}

if(_21s1 === '0'){$('.q21 ww').hide();$('.q21 ch').show();}
if(_21s1 === '1'){$('.q21 .b1').addClass('wbtr');}
if(_21s1 === '2'){$('.q21 .b2').addClass('wbtr');}
if(_21s1 === '3'){$('.q21 .b3').addClass('wbtr');}

if(_22s1 === '0'){$('.q22 ww').hide();$('.q22 ch').show();}
if(_22s1 === '1'){$('.q22 .b1').addClass('wbtr');}
if(_22s1 === '2'){$('.q22 .b2').addClass('wbtr');}
if(_22s1 === '3'){$('.q22 .b3').addClass('wbtr');}

if(_23s1 === '0'){$('.q23 ww').hide();$('.q23 ch').show();}
if(_23s1 === '1'){$('.q23 .b1').addClass('wbtr');}
if(_23s1 === '2'){$('.q23 .b2').addClass('wbtr');}
if(_23s1 === '3'){$('.q23 .b3').addClass('wbtr');}

if(_24s1 === '0'){$('.q24 ww').hide();$('.q24 ch').show();}
if(_24s1 === '1'){$('.q24 .b1').addClass('wbtr');}
if(_24s1 === '2'){$('.q24 .b2').addClass('wbtr');}
if(_24s1 === '3'){$('.q24 .b3').addClass('wbtr');}

if(_25s1 === '0'){$('.q25 ww').hide();$('.q25 ch').show();}
if(_25s1 === '1'){$('.q25 .b1').addClass('wbtr');}
if(_25s1 === '2'){$('.q25 .b2').addClass('wbtr');}
if(_25s1 === '3'){$('.q25 .b3').addClass('wbtr');}

if(_26s1 === '0'){$('.q26 ww').hide();$('.q26 ch').show();}
if(_26s1 === '1'){$('.q26 .b1').addClass('wbtr');}
if(_26s1 === '2'){$('.q26 .b2').addClass('wbtr');}
if(_26s1 === '3'){$('.q26 .b3').addClass('wbtr');}

if(_27s1 === '0'){$('.q27 ww').hide();$('.q27 ch').show();}
if(_27s1 === '1'){$('.q27 .b1').addClass('wbtr');}
if(_27s1 === '2'){$('.q27 .b2').addClass('wbtr');}
if(_27s1 === '3'){$('.q27 .b3').addClass('wbtr');}

if(_28s1 === '0'){$('.q28 ww').hide();$('.q28 ch').show();}
if(_28s1 === '1'){$('.q28 .b1').addClass('wbtr');}
if(_28s1 === '2'){$('.q28 .b2').addClass('wbtr');}
if(_28s1 === '3'){$('.q28 .b3').addClass('wbtr');}

if(_29s1 === '0'){$('.q29 ww').hide();$('.q29 ch').show();}
if(_29s1 === '1'){$('.q29 .b1').addClass('wbtr');}
if(_29s1 === '2'){$('.q29 .b2').addClass('wbtr');}
if(_29s1 === '3'){$('.q29 .b3').addClass('wbtr');}

if(_30s1 === '0'){$('.q30 ww').hide();$('.q30 ch').show();}
if(_30s1 === '1'){$('.q30 .b1').addClass('wbtr');}
if(_30s1 === '2'){$('.q30 .b2').addClass('wbtr');}
if(_30s1 === '3'){$('.q30 .b3').addClass('wbtr');}

if(_31s1 === '0'){$('.q31 ww').hide();$('.q31 ch').show();}
if(_31s1 === '1'){$('.q31 .b1').addClass('wbtr');}
if(_31s1 === '2'){$('.q31 .b2').addClass('wbtr');}
if(_31s1 === '3'){$('.q31 .b3').addClass('wbtr');}

if(_32s1 === '0'){$('.q32 ww').hide();$('.q32 ch').show();}
if(_32s1 === '1'){$('.q32 .b1').addClass('wbtr');}
if(_32s1 === '2'){$('.q32 .b2').addClass('wbtr');}
if(_32s1 === '3'){$('.q32 .b3').addClass('wbtr');}

if(_33s1 === '0'){$('.q33 ww').hide();$('.q33 ch').show();}
if(_33s1 === '1'){$('.q33 .b1').addClass('wbtr');}
if(_33s1 === '2'){$('.q33 .b2').addClass('wbtr');}
if(_33s1 === '3'){$('.q33 .b3').addClass('wbtr');}

if(_34s1 === '0'){$('.q34 ww').hide();$('.q34 ch').show();}
if(_34s1 === '1'){$('.q34 .b1').addClass('wbtr');}
if(_34s1 === '2'){$('.q34 .b2').addClass('wbtr');}
if(_34s1 === '3'){$('.q34 .b3').addClass('wbtr');}

if(_35s1 === '0'){$('.q35 ww').hide();$('.q35 ch').show();}
if(_35s1 === '1'){$('.q35 .b1').addClass('wbtr');}
if(_35s1 === '2'){$('.q35 .b2').addClass('wbtr');}
if(_35s1 === '3'){$('.q35 .b3').addClass('wbtr');}

if(_36s1 === '0'){$('.q36 ww').hide();$('.q36 ch').show();}
if(_36s1 === '1'){$('.q36 .b1').addClass('wbtr');}
if(_36s1 === '2'){$('.q36 .b2').addClass('wbtr');}
if(_36s1 === '3'){$('.q36 .b3').addClass('wbtr');}

if(_37s1 === '0'){$('.q37 ww').hide();$('.q37 ch').show();}
if(_37s1 === '1'){$('.q37 .b1').addClass('wbtr');}
if(_37s1 === '2'){$('.q37 .b2').addClass('wbtr');}
if(_37s1 === '3'){$('.q37 .b3').addClass('wbtr');}

if(_38s1 === '0'){$('.q38 ww').hide();$('.q38 ch').show();}
if(_38s1 === '1'){$('.q38 .b1').addClass('wbtr');}
if(_38s1 === '2'){$('.q38 .b2').addClass('wbtr');}
if(_38s1 === '3'){$('.q38 .b3').addClass('wbtr');}

if(_39s1 === '0'){$('.q39 ww').hide();$('.q39 ch').show();}
if(_39s1 === '1'){$('.q39 .b1').addClass('wbtr');}
if(_39s1 === '2'){$('.q39 .b2').addClass('wbtr');}
if(_39s1 === '3'){$('.q39 .b3').addClass('wbtr');}

if(_40s1 === '0'){$('.q40 ww').hide();$('.q40 ch').show();}
if(_40s1 === '1'){$('.q40 .b1').addClass('wbtr');}
if(_40s1 === '2'){$('.q40 .b2').addClass('wbtr');}
if(_40s1 === '3'){$('.q40 .b3').addClass('wbtr');}

if(_41s1 === '0'){$('.q41 ww').hide();$('.q41 ch').show();}
if(_41s1 === '1'){$('.q41 .b1').addClass('wbtr');}
if(_41s1 === '2'){$('.q41 .b2').addClass('wbtr');}
if(_41s1 === '3'){$('.q41 .b3').addClass('wbtr');}

if(_42s1 === '0'){$('.q42 ww').hide();$('.q42 ch').show();}
if(_42s1 === '1'){$('.q42 .b1').addClass('wbtr');}
if(_42s1 === '2'){$('.q42 .b2').addClass('wbtr');}
if(_42s1 === '3'){$('.q42 .b3').addClass('wbtr');}

if(_43s1 === '0'){$('.q43 ww').hide();$('.q43 ch').show();}
if(_43s1 === '1'){$('.q43 .b1').addClass('wbtr');}
if(_43s1 === '2'){$('.q43 .b2').addClass('wbtr');}
if(_43s1 === '3'){$('.q43 .b3').addClass('wbtr');}

if(_44s1 === '0'){$('.q44 ww').hide();$('.q44 ch').show();}
if(_44s1 === '1'){$('.q44 .b1').addClass('wbtr');}
if(_44s1 === '2'){$('.q44 .b2').addClass('wbtr');}
if(_44s1 === '3'){$('.q44 .b3').addClass('wbtr');}

if(_45s1 === '0'){$('.q45 ww').hide();$('.q45 ch').show();}
if(_45s1 === '1'){$('.q45 .b1').addClass('wbtr');}
if(_45s1 === '2'){$('.q45 .b2').addClass('wbtr');}
if(_45s1 === '3'){$('.q45 .b3').addClass('wbtr');}

if(_46s1 === '0'){$('.q46 ww').hide();$('.q46 ch').show();}
if(_46s1 === '1'){$('.q46 .b1').addClass('wbtr');}
if(_46s1 === '2'){$('.q46 .b2').addClass('wbtr');}
if(_46s1 === '3'){$('.q46 .b3').addClass('wbtr');}

if(_47s1 === '0'){$('.q47 ww').hide();$('.q47 ch').show();}
if(_47s1 === '1'){$('.q47 .b1').addClass('wbtr');}
if(_47s1 === '2'){$('.q47 .b2').addClass('wbtr');}
if(_47s1 === '3'){$('.q47 .b3').addClass('wbtr');}

if(_48s1 === '0'){$('.q48 ww').hide();$('.q48 ch').show();}
if(_48s1 === '1'){$('.q48 .b1').addClass('wbtr');}
if(_48s1 === '2'){$('.q48 .b2').addClass('wbtr');}
if(_48s1 === '3'){$('.q48 .b3').addClass('wbtr');}

if(_49s1 === '0'){$('.q49 ww').hide();$('.q49 ch').show();}
if(_49s1 === '1'){$('.q49 .b1').addClass('wbtr');}
if(_49s1 === '2'){$('.q49 .b2').addClass('wbtr');}
if(_49s1 === '3'){$('.q49 .b3').addClass('wbtr');}

if(_50s1 === '0'){$('.q50 ww').hide();$('.q50 ch').show();}
if(_50s1 === '1'){$('.q50 .b1').addClass('wbtr');}
if(_50s1 === '2'){$('.q50 .b2').addClass('wbtr');}
if(_50s1 === '3'){$('.q50 .b3').addClass('wbtr');}

if(_51s1 === '0'){$('.q51 ww').hide();$('.q51 ch').show();}
if(_51s1 === '1'){$('.q51 .b1').addClass('wbtr');}
if(_51s1 === '2'){$('.q51 .b2').addClass('wbtr');}
if(_51s1 === '3'){$('.q51 .b3').addClass('wbtr');}

if(_52s1 === '0'){$('.q52 ww').hide();$('.q52 ch').show();}
if(_52s1 === '1'){$('.q52 .b1').addClass('wbtr');}
if(_52s1 === '2'){$('.q52 .b2').addClass('wbtr');}
if(_52s1 === '3'){$('.q52 .b3').addClass('wbtr');}

if(_53s1 === '0'){$('.q53 ww').hide();$('.q53 ch').show();}
if(_53s1 === '1'){$('.q53 .b1').addClass('wbtr');}
if(_53s1 === '2'){$('.q53 .b2').addClass('wbtr');}
if(_53s1 === '3'){$('.q53 .b3').addClass('wbtr');}

if(_54s1 === '0'){$('.q54 ww').hide();$('.q54 ch').show();}
if(_54s1 === '1'){$('.q54 .b1').addClass('wbtr');}
if(_54s1 === '2'){$('.q54 .b2').addClass('wbtr');}
if(_54s1 === '3'){$('.q54 .b3').addClass('wbtr');}

if(_55s1 === '0'){$('.q55 ww').hide();$('.q55 ch').show();}
if(_55s1 === '1'){$('.q55 .b1').addClass('wbtr');}
if(_55s1 === '2'){$('.q55 .b2').addClass('wbtr');}
if(_55s1 === '3'){$('.q55 .b3').addClass('wbtr');}

if(_56s1 === '0'){$('.q56 ww').hide();$('.q56 ch').show();}
if(_56s1 === '1'){$('.q56 .b1').addClass('wbtr');}
if(_56s1 === '2'){$('.q56 .b2').addClass('wbtr');}
if(_56s1 === '3'){$('.q56 .b3').addClass('wbtr');}

if(_57s1 === '0'){$('.q57 ww').hide();$('.q57 ch').show();}
if(_57s1 === '1'){$('.q57 .b1').addClass('wbtr');}
if(_57s1 === '2'){$('.q57 .b2').addClass('wbtr');}
if(_57s1 === '3'){$('.q57 .b3').addClass('wbtr');}

if(_58s1 === '0'){$('.q58 ww').hide();$('.q58 ch').show();}
if(_58s1 === '1'){$('.q58 .b1').addClass('wbtr');}
if(_58s1 === '2'){$('.q58 .b2').addClass('wbtr');}
if(_58s1 === '3'){$('.q58 .b3').addClass('wbtr');}

if(_59s1 === '0'){$('.q59 ww').hide();$('.q59 ch').show();}
if(_59s1 === '1'){$('.q59 .b1').addClass('wbtr');}
if(_59s1 === '2'){$('.q59 .b2').addClass('wbtr');}
if(_59s1 === '3'){$('.q59 .b3').addClass('wbtr');}

if(_60s1 === '0'){$('.q60 ww').hide();$('.q60 ch').show();}
if(_60s1 === '1'){$('.q60 .b1').addClass('wbtr');}
if(_60s1 === '2'){$('.q60 .b2').addClass('wbtr');}
if(_60s1 === '3'){$('.q60 .b3').addClass('wbtr');}

if(sessionStorage.getItem('cl')){
mylanguage();
$('cl').html('<button id="lc2">ENGLISH</button><button id="lc1"><ben>বাংলা</button>');} 
else {
$('cl').html('<button id="lc1">ENGLISH</button><button id="lc2"><ben>বাংলা</button>');}
}

  function storagereset(){
  $("#resetpopup,#belowpopup").hide();$('#popup2').show();
var remove = ['qs1','ns1','1s1','2s1','3s1','4s1','5s1','6s1','7s1','8s1','9s1','10s1','11s1','12s1','13s1','14s1','15s1','16s1','17s1','18s1','19s1','20s1','21s1','22s1','23s1','24s1','25s1','26s1','27s1','28s1','29s1','30s1','31s1','32s1','33s1','34s1','35s1','36s1','37s1','38s1','39s1','40s1','41s1','42s1','43s1','44s1','45s1','46s1','47s1','48s1','49s1','50s1','51s1','52s1','53s1','54s1','55s1','56s1','57s1','58s1','59s1','60s1']; for (key of remove) {l.removeItem(key);}location.reload(); }
